@import "~@oup/shared-front-end/dist/styles/themes/base/sizes";
@import "~@oup/shared-front-end/dist/styles/themes/base/typography";
@import "~@oup/shared-front-end/dist/styles/breakpoints";

.stepContainer {
  display: flex;
  max-width: 63.5rem;
  flex-direction: row;
  padding-top: $gap-6;
  gap: $gap-5;

  @media (max-width: $screen-width-1024) {
    padding: $gap-4;
  }

  @media (max-width: $screen-width-600) {
    height: auto;
    flex-direction: column;
    padding-top: $gap-4;
  }

  .contentContainer {
    display: flex;
    flex-direction: column;
    padding: $gap-5 0;
    gap: $gap-6;

    @media (max-width: $screen-width-600) {
      padding: 0 $gap-4;
    }
  }

  .inputContainer {
    display: grid;
    gap: $gap-5
  }

  .heading {
    padding: 0;
    font-family: $font-family-display-font;
  }

  .subHeading {
    padding: $gap-4 $gap-0 $gap-0;
    font-size: $font-size-large;
    font-weight: $font-weight-600;

    a {
      margin-left: 7px;
    }
  }

  .link {
    text-decoration: underline;
  }

  .rightColumnContainer {
    width: 50%;
    padding: $gap-5 0;

    @media (max-width: $screen-width-600) {
      width: 100%;
      padding: 0 $gap-4;
    }

    .infoBoxContent p {
      margin-bottom: $gap-2;
    }
  }
}
